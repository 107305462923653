<script>

    export default {
        data() {
            this.searchNoClear = {current: 1, size: 10};
            this.sels = [];
            return {
                openHs: false,
                table: {
                    loading: false,
                    data: [],
                    total: 0,
                    height: 500
                }
            }
        },
        props: {
            selection: {
                type: Boolean,
                default: false
            },
            visible: Boolean,
            value: [Array, String],
            codeKey: String,
        },
        watch: {
            visible: {
                handler(newVal) {
                    if (newVal) {
                        this.$nextTick(() => {
                            this.$emit('table-resize');
                        });
                    }
                },
                immediate: true
            },
            'table.data': {
                handler() {
                    this.initSelection();
                },
                immediate: true
            }
        },
        mounted() {
            this.refresh();
        },
        methods: {
            refresh() { //默认刷新列表方法
                this.table.loading = true;
                return this.refreshService(Object.assign(this.search, this.searchNoClear)).then((response) => {
                    let ret = response.data;
                    this.table.data = ret.records;
                    this.table.total = ret.total || 0;
                    if (this.searchNoClear.current !==1 &&(!ret.records || ret.records=== 0)){
                        this.searchNoClear.current--
                        this.refresh();
                        return ;
                    }
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.table.loading = false)
            },
            handleSizeChange(val) { //默认当前页大小变化
                this.searchNoClear.size = val;
                return this.refresh();
            },
            handleCurrentChange(val) { //默认当前页变化
                this.searchNoClear.current = val;
                return this.refresh();
            },
            handleSearch() { //默认查询
                this.searchNoClear.current = 1;
                return this.refresh();
            },
            handleClear() { //默认清空方法
                Object.keys(this.search).forEach((key) => this.search[key] = '')
            },
            handleSelectionChange(sels) { //默认带有复选框的类表，复选变化触发
                this.sels = sels;
            },
            handleConfirms() {
                if (!this.sels || this.sels.length < 1) {
                    return this.$message.warning("请至少选择一条数据！");
                }
                this.$emit("selected", this.sels);
            },
            handleConfirm(row) {
                this.$emit("selected", [row]);
            },
            initSelection() {
                const tableData = this.table.data;
                const tableRef = this.$refs.table;
                if (tableRef && this.codeKey && this.value && tableData && tableData.length) {
                    const selectedCodes = Array.isArray(this.value) ? this.value : [this.value];
                    if (this.selection) {
                        const selectedRows = tableData.filter(row => selectedCodes.indexOf(row[this.codeKey]) > -1);
                        if (selectedRows.length) {
                            this.$nextTick(() => selectedRows.forEach(row => tableRef.toggleRowSelection(row, true)))
                        }
                    } else {
                        //单行选择时高亮选中行，需配合el-table的 highlight-current-row属性使用
                        const found = tableData.find(row => selectedCodes.indexOf(row[this.codeKey]) > -1);
                        if (found) {
                            tableRef.setCurrentRow(found);
                        }
                    }
                }
            }
        }
    }
</script>

<style scoped>
</style>
