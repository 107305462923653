<template>
    <el-tooltip class="item" effect="dark" :content="dm" placement="top" :disabled="!dm">
        <el-input size="small" v-model="text" v-if="edit" readonly/>
        <span v-else>{{ text }}</span>
    </el-tooltip>
</template>

<script>
//此组件用于回显各种根据单位代码生成的子孙代码 含库区、仓房、廒间、货位、货位卡等
const lens = {
    jhdh: {len: 25, yearStart: 18, yearEnd: 22, numberStart: 22, numberEnd: 25},
    jhmxdh: {len: 50, yearStart: 18, yearEnd: 22, jhNumStart: 22, jhNumEnd: 25, mxNumStart: 46, mxNumEnd: 50},
    hth: {len: 35, headStart: 21, headEnd: 25, monthStart: 25, monthEnd: 31, numberStart: 31, numberEnd: 35},
    crktzd: {len: 13, headStart: 0, headEnd: 2, dayStart: 2, dayEnd: 10, numberStart: 10, numberEnd: 13},
    crkjsd: {len: 33, dayStart: 21, dayEnd: 29, numberStart: 29, numberEnd: 33},
    dcdh: {len: 30, dayStart: 18, dayEnd: 26, numberStart: 26, numberEnd: 30},
    xzzbdh: {len: 41, dayStart: 30, dayEnd: 38, numberStart: 38, numberEnd: 41},
    tfzydh: {len: 41, dayStart: 30, dayEnd: 38, numberStart: 38, numberEnd: 41},
    xzzydh: {len: 36, dayStart: 25, dayEnd: 33, numberStart: 33, numberEnd: 36},
    hcjcdh: {len: 42, dayStart: 30, dayEnd: 38, numberStart: 38, numberEnd: 42},
    shangPinBM: {len: 11, headStart: 0, headEnd: 2, dayStart: 2, dayEnd: 8, numberStart: 8, numberEnd: 11},
}

export default {
    name: "XJiHuaDH",
    computed: {
        text() {
            if (this.original || !this.dm) {
                return this.dm;
            }
            //计划单号
            if (this.dm.length === lens.jhdh.len) {
                return this.getJhdh();
            }
            //计划明细单号
            if (this.dm.length === lens.jhmxdh.len) {
                return this.getJhmxdh();
            }
            //合同号
            if (this.dm.length === lens.hth.len) {
                return this.getHth();
            }
            //出入库通知单号
            if (this.dm.length === lens.crktzd.len) {
                return this.getCrktzd();
            }
            //出入库结算单号
            if (this.dm.length === lens.crkjsd.len) {
                return this.getCrkjsd();
            }
            //倒仓单号
            if (this.dm.length === lens.dcdh.len) {
                return this.getDcdh();
            }
            //通风作业单号
            if (this.dm.length === lens.tfzydh.len) {
                return this.getTfzydh();
            }
            //熏蒸作业单号
            if (this.dm.length === lens.xzzydh.len) {
                return this.getXzzydh();
            }
            //害虫检测单号
            if (this.dm.length === lens.hcjcdh.len) {
                return this.getHcjcdh();
            }
            //商品编码
            if (this.dm.length === lens.shangPinBM.len) {
                return this.getSpbm();
            }

        }
    },
    props: {
        dm: {
            type: String,
            default: ""
        },
        original: {
            type: Boolean,
            default: false
        },
        connector: {
            type: String,
            default: "-"
        },
        edit: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getJhdh() {
            let year = this.dm.substring(lens.jhdh.yearStart, lens.jhdh.yearEnd);
            let number = this.dm.substring(lens.jhdh.numberStart, lens.jhdh.numberEnd);
            return year.concat(this.connector).concat(number);
        },
        getJhmxdh() {
            let year = this.dm.substring(lens.jhmxdh.yearStart, lens.jhmxdh.yearEnd);
            let number = this.dm.substring(lens.jhmxdh.jhNumStart, lens.jhmxdh.jhNumEnd);
            let number2 = this.dm.substring(lens.jhmxdh.mxNumStart, lens.jhmxdh.mxNumEnd);
            return year.concat(this.connector).concat(number).concat(this.connector).concat(number2);
        },
        getHth() {
            let head = this.dm.substring(lens.hth.headStart, lens.hth.headEnd);
            let month = this.dm.substring(lens.hth.monthStart, lens.hth.monthEnd);
            let number = this.dm.substring(lens.hth.numberStart, lens.hth.numberEnd);
            return head.concat(this.connector).concat(month).concat(this.connector).concat(number);
        },
        getCrktzd() {
            let head = this.dm.substring(lens.crktzd.headStart, lens.crktzd.headEnd);
            let day = this.dm.substring(lens.crktzd.dayStart, lens.crktzd.dayEnd);
            let number = this.dm.substring(lens.crktzd.numberStart, lens.crktzd.numberEnd);
            return head.concat(this.connector).concat(day).concat(this.connector).concat(number);
        },
        getCrkjsd(){
            let day = this.dm.substring(lens.crkjsd.dayStart, lens.crkjsd.dayEnd);
            let number = this.dm.substring(lens.crkjsd.numberStart, lens.crkjsd.numberEnd);
            return day.concat(this.connector).concat(number);
        },
        getDcdh(){
            let day = this.dm.substring(lens.dcdh.dayStart, lens.dcdh.dayEnd);
            let number = this.dm.substring(lens.dcdh.numberStart, lens.dcdh.numberEnd);
            return day.concat(this.connector).concat(number);
        },
        getXzzbdh(){
            let day = this.dm.substring(lens.xzzbdh.dayStart, lens.xzzbdh.dayEnd);
            let number = this.dm.substring(lens.xzzbdh.numberStart, lens.xzzbdh.numberEnd);
            return day.concat(this.connector).concat(number);
        },
        getTfzydh(){
            let day = this.dm.substring(lens.tfzydh.dayStart, lens.tfzydh.dayEnd);
            let number = this.dm.substring(lens.tfzydh.numberStart, lens.tfzydh.numberEnd);
            return day.concat(this.connector).concat(number);
        },
        getXzzydh(){
            let day = this.dm.substring(lens.xzzydh.dayStart, lens.xzzydh.dayEnd);
            let number = this.dm.substring(lens.xzzydh.numberStart, lens.xzzydh.numberEnd);
            return day.concat(this.connector).concat(number);
        },
        getHcjcdh(){
            let day = this.dm.substring(lens.hcjcdh.dayStart, lens.hcjcdh.dayEnd);
            let number = this.dm.substring(lens.hcjcdh.numberStart, lens.hcjcdh.numberEnd);
            return day.concat(this.connector).concat(number);
        },
        getSpbm() {
            let head = this.dm.substring(lens.shangPinBM.headStart, lens.shangPinBM.headEnd);
            let day = this.dm.substring(lens.shangPinBM.dayStart, lens.shangPinBM.dayEnd);
            let number = this.dm.substring(lens.shangPinBM.numberStart, lens.shangPinBM.numberEnd);
            return head.concat(this.connector).concat(day).concat(this.connector).concat(number);
        },


    }
}
</script>

<style scoped>

</style>
