<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
                destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-divider title="基本信息"></x-divider>
            <x-detail-item prop="panDianDanID" label="盘点单ID" />
            <x-detail-item prop="panDianCangKuID" label="盘点仓库ID" />
            <x-detail-item prop="panDianCangKuMC" label="盘点仓库名称" />
<!--            <x-detail-item prop="caoZuoRenID" label="操作人ID" />-->
            <x-detail-item prop="caoZuoRen" label="操作人" />
            <x-detail-item prop="panDianKaiShiSJ" label="盘点开始时间" />
            <x-detail-item prop="panDianJieShuSJ" label="盘点结束时间" />
            <x-detail-item prop="panDianShangPinZhongLei" label="盘点商品种类" />
            <x-detail-item prop="panDianZT" label="盘点状态" >
                <x-dict-show :code="entity.panDianZT" dictType="T_PAN_DIAN_STATUS"/>
            </x-detail-item>
            <x-detail-item prop="beiZhu" label="备注" />
            <x-detail-item prop="title" label="主要事项" />
            <x-divider title="审核信息"></x-divider>
            <x-detail-item prop="applicant" label="申请人" />
            <x-detail-item prop="applyDate" label="申请时间" class="w66"/>
            <x-detail-item label="审核状态">
                <x-dict-show :code="entity.auditStatus" dictType="T_AUDIT_STATUS"/>
            </x-detail-item>
            <x-detail-item prop="auditorName" label="审核人" />
            <x-detail-item prop="auditDate" label="审核日期" />
            <x-detail-item prop="auditMind" label="审核意见" class="line"/>
        </x-detail>
        <x-divider title="盘点信息详情"></x-divider>
        <el-table :data="entity.panDianXinXiXiangQings" border class="x-detail-table">
            <el-table-column width="60" label="序号" prop="sort" />
<!--            <el-table-column width="150" prop="panDianDanID" label="盘点单ID" />-->
<!--            <el-table-column width="150" prop="panDianKaiShiSJ" label="盘点开始时间" />-->
<!--            <el-table-column width="150" prop="panDianJieShuSJ" label="盘点结束时间" />-->
<!--            <el-table-column width="150" prop="panDianCangKuID" label="盘点仓库ID" />-->
<!--            <el-table-column width="150" prop="panDianCangKuMC" label="盘点仓库名称" />-->
            <el-table-column width="150" prop="shangPinID" label="商品ID" />
            <el-table-column width="150" prop="shangPinMC" label="商品名称" />
            <el-table-column width="150" prop="guiGe" label="规格" />
            <el-table-column width="150" prop="tiaoMa" label="条码" />
            <el-table-column width="150" prop="danWei" label="单位" />
            <el-table-column width="150" prop="chengBenJia" label="成本价" />
            <el-table-column width="150" prop="shengChanRQ" label="生产日期" />
            <el-table-column width="150" prop="baoZhiQiTian" label="保质期（天）" />
            <el-table-column width="150" prop="kuCunSL" label="库存数量" />
            <el-table-column width="150" prop="panDianSL" label="盘点数量" />
            <el-table-column width="150" prop="panKuiSL" label="盘亏数量" />
            <el-table-column width="150" prop="panKuiJinE" label="盘亏金额" />
            <el-table-column width="150" prop="panYingSL" label="盘盈数量" />
            <el-table-column width="150" prop="panYingJinE" label="盘盈金额" />
            <el-table-column width="150" prop="beiZhu" label="备注" />
        </el-table>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/kcgl/PanDianXX";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                panDianDanID: "", // 盘点单ID
                panDianCangKuID: "", // 盘点仓库ID
                panDianCangKuMC: "", // 盘点仓库名称
                caoZuoRenID: "", // 操作人ID
                caoZuoRen: "", // 操作人
                panDianKaiShiSJ: "", // 盘点开始时间
                panDianJieShuSJ: "", // 盘点结束时间
                panDianShangPinZhongLei: "", // 盘点商品种类
                panDianZT: "", // 盘点状态
                beiZhu: "", // 备注
                title: "", // 主要事项
                applicant: "", // 申请人
                applyDate: "", // 申请日期
                auditStatus: "", // 审核状态
                auditorName: "", // 审核人
                auditDate: "", // 审核日期
                auditMind: "", // 审核意见
                panDianXinXiXiangQings: [],
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>