<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" :close-on-click-modal="false"
                destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <x-divider title="基本信息"></x-divider>
            <el-form-item label="操作人" prop="caoZuoRen" >
                {{form.caoZuoRen}}
            </el-form-item>
            <el-form-item label="备注" prop="beiZhu" >
                <el-input v-model="form.beiZhu" size="small" maxlength="256" show-word-limit/>
            </el-form-item>
            <el-form-item label="主要事项" prop="title" >
                <el-input v-model="form.title" size="small" maxlength="100" show-word-limit/>
            </el-form-item>
            <x-divider title="审核信息"></x-divider>
            <el-form-item label="审核状态">
                <x-dict-show :code="form.auditStatus" dictType="T_AUDIT_STATUS"/>
            </el-form-item>
            <el-form-item label="审核日期">{{form.auditDate}}</el-form-item>
            <el-form-item label="审核人">{{form.auditorName}}</el-form-item>
            <el-form-item label="审核意见" class="line">{{form.auditMind}}</el-form-item>
            <x-divider title="盘点信息详情"></x-divider>
            <x-selector
                    placement="center"
                    selector="KuCunZhuangKuangSelector"
                    @selected="handleClSelect"
                    code-key="id"
                    :param="{'cangKuMC': this.panDianCangKuMC}"
                    v-if="this.panDianCangKuMC != '' && this.panDianCangKuMC != null"
                    selection
                    width="1000px">
                <el-button size="mini" type="success" round >添加商品</el-button>
            </x-selector>
            <x-row :rows="form.panDianXinXiXiangQings" :rowModel="panDianXinXiXiangQingsModel" no-add-btn>
                <x-row-cell width="200" read="shangPinMC" title="商品名称"/>
                <x-row-cell width="200" read="panDianCangKuMC" title="仓库名称"/>
                <x-row-cell width="150" read="guiGe" title="规格"/>
                <x-row-cell width="150" read="tiaoMa" title="条码"/>
                <x-row-cell width="150" read="huoHao" title="货号"/>
                <x-row-cell width="100" read="danWei" title="单位"/>
                <x-row-cell width="100" read="chengBenJia" title="成本价"/>
                <x-row-cell width="200" read="shengChanRQ" title="生产日期"/>
                <x-row-cell width="100" read="baoZhiQiTian" title="保质期（天）"/>
                <x-row-cell width="100" read="kuCunSL" title="库存数量"/>
                <x-row-cell width="100" title="盘点数量" prop="panDianSL">
                    <el-input slot-scope="{row}" v-model="row.panDianSL" size="small" maxlength="18"/>
                </x-row-cell>
                <x-row-cell title="备注" width="200" prop="beiZhu">
                    <el-input slot-scope="{row}" v-model="row.beiZhu" size="small" maxlength="18"/>
                </x-row-cell>
            </x-row>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <!--  <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>-->
            <el-button type="primary" @click="commit" icon="el-icon-document-add">提 交</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/kcgl/PanDianXX";
    import {deepCopy, deepMerge} from "@/util/objects"
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import XUserSelector from "@/components/x/selector/XUserSelector";
    import {selector,getGongJiCangKu} from "@/service/cggl/CangKu";
    import {getListByCangKuMC}from "@/service/kcgl/KuCunZhuangKuang";
    import KuCunZhuangKuangSelector from "@/view/selector/KuCunZhuangKuangSelector";
    import {DICT_TYPE_PAN_DIAN_STATUS} from "@/util/constant";

    export default {
        mixins: [XEditBase],
        components: {XUserSelector,KuCunZhuangKuangSelector,DICT_TYPE_PAN_DIAN_STATUS},
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                panDianDanID: [], // 盘点单ID
                panDianCangKuID: [], // 盘点仓库ID
                panDianCangKuMC: [], // 盘点仓库名称
                caoZuoRenID: [], // 操作人ID
                caoZuoRen: [], // 操作人
                panDianKaiShiSJ: [], // 盘点开始时间
                panDianJieShuSJ: [], // 盘点结束时间
                panDianShangPinZhongLei: [], // 盘点商品种类
                panDianZT: [], // 盘点状态
                beiZhu: [], // 备注
                title: [], // 主要事项
            },
            this.titlePrefix = '盘点信息';
            this.panDianXinXiXiangQingsModel = {
                id: "",
                kuCunZhuangKuangId: "",//库存id
                panDianDanID: "", // 盘点单ID
                panDianKaiShiSJ: "", // 盘点开始时间
                panDianJieShuSJ: "", // 盘点结束时间
                panDianCangKuID: "", // 盘点仓库ID
                panDianCangKuMC: "", // 盘点仓库名称
                shangPinID: "", // 商品ID
                shangPinMC: "", // 商品名称
                guiGe: "", // 规格
                tiaoMa: "", // 条码
                huoHao:'',//货号
                danWei: "", // 单位
                chengBenJia: "", // 成本价
                shengChanRQ: "",//生产日期
                baoZhiQiTian: "",//保质期（天）
                kuCunSL: "", // 库存数量
                panDianSL: "", // 盘点数量
                panKuiSL: "", // 盘亏数量
                panKuiJinE: "", // 盘亏金额
                panYingSL: "", // 盘盈数量
                panYingJinE: "", // 盘盈金额
                beiZhu: "", // 备注
            },
            this.defaultForm = {
                id: null,
                panDianDanID: "", // 盘点单ID
                panDianCangKuID: "", // 盘点仓库ID
                panDianCangKuMC: "", // 盘点仓库名称
                caoZuoRenID: this.$store.getters.user.id, // 操作人ID
                caoZuoRen: this.$store.getters.user.name, // 操作人
                panDianKaiShiSJ: "", // 盘点开始时间
                panDianJieShuSJ: "", // 盘点结束时间
                panDianShangPinZhongLei: "", // 盘点商品种类
                panDianZT: DICT_TYPE_PAN_DIAN_STATUS.WPD.key, // 盘点状态
                beiZhu: "", // 备注
                title: "", // 主要事项
                auditStatus: "",
                auditDate: "",
                auditorName: "",
                auditMind: "",
                panDianXinXiXiangQings: [],
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        },
        data() {
            return {
                options:[],
                spxxs:[],
                panDianCangKuMC:'',
                panDianCangKuID:'',
            }
        },
        mounted() {
            this.getGongJiCangKu();
        },
        methods: {
            open(){
                //验证是编辑还是添加并处理
                if (this.isUpdate) {
                    return this.getUpdateService(this.editor.id).then((response) => {
                        this.form = deepMerge(this.defaultForm, response.data);
                        let pdxq = []
                        response.data.panDianXinXiXiangQings.forEach(pdxx=>{
                            let mergePdxx = deepMerge(this.panDianXinXiXiangQingsModel,pdxx)
                            Object.keys(mergePdxx).forEach(key=>{
                                if(!mergePdxx[key]){
                                    mergePdxx[key] = ''
                                }
                            })
                            pdxq.push(mergePdxx)
                        })
                        this.form.panDianXinXiXiangQings = pdxq
                        //清空校验
                        this._clearValidate();
                        return response;
                    })
                } else {
                    this.form = deepCopy(this.defaultForm);
                    //清空校验
                    this._clearValidate();
                    return Promise.resolve({code: 200});
                }
            },
            handleClSelect(cls) {
                let cc = [];
                this.form.panDianXinXiXiangQings.forEach(item=>cc.push(item.kuCunZhuangKuangId));
                    cls.forEach(cl => {
                        let obj = {};
                        if(!cc.includes(cl.id)){
                            obj.kuCunZhuangKuangId = cl.id;
                            obj.panDianCangKuID = cl.cangKuBH || '';
                            obj.panDianCangKuMC = cl.cangKuMC || '';
                            obj.shangPinID = cl.shangPinId || '';
                            obj.shangPinMC = cl.shangPinMC || '';
                            obj.huoHao = cl.shangPinHuoHao || '';
                            obj.tiaoMa = cl.shangPinTiaoMa || '';
                            obj.guiGe = cl.shangPinGuiGe || '';
                            obj.kuCunSL = cl.shiJiKuCun || '';
                            obj.chengBenJia = cl.chengBenJia || '';
                            obj.danWei = cl.shangPinDW || '';
                            obj.shengChanRQ = cl.shengChanRQ || '';
                            obj.baoZhiQiTian = cl.baoZhiQiTian || '';
                            this.form.panDianXinXiXiangQings.push(obj);
                        }

                    });
            },
            async save(callback) { // 通用save方法 增加回调
                this.loading = true;
                let isvalidate = false;
                try {
                    await this.$refs.form.validate((valid, message) => {
                        if (valid) { // 如果校验通过则提交
                            isvalidate = true;
                        } else { // 校验不通过
                            this.$message.error("有必填项没有填写请检查！");
                            this.loading = false;
                        }
                    });
                } catch (e) {
                    this.loading = false;
                    return;
                }
                if (!isvalidate) return;
                let api = this.isUpdate ? this.updateService : this.addService;

                var rows = this.form.panDianXinXiXiangQings;
                if(rows.length === 0){
                    this.$message.error("请先添加盘点商品再提交");
                    return ;
                }

                const response = await api(this.form).finally(() => {
                    this.loading = false; //TODO 更好的处理保存按钮加载问题
                });//执行保存方法
                if (callback && typeof callback === 'function') {
                    callback(response);
                }
                //打印提示信息
                this.$message.success(response.msg);
                this.$emit("submitted", response);
                this.cancel();
            },
            getGongJiCangKu(){
                getGongJiCangKu().then((res)=>{
                    this.panDianCangKuMC = res.data.cangKuMC;
                    this.panDianCangKuID = res.data.cangKuBH;
                })
            },
            //获取仓库列表
            getCangKuOptions() {
                selector().then((response) => {
                    let cangKu;
                    cangKu = response.data[0];
                    this.panDianCangKuMC = cangKu.cangKuMC;
                    this.panDianCangKuID = cangKu.cangKuBH;
                })
            },
            //获取商品信息列表
            change(cangKuMC){
                getListByCangKuMC(cangKuMC).then((response) => {
                    this.spxxs = response.data
                })
            }

        }
    }
</script>

<style scoped>

</style>
